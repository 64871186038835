import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dropdown, Form, Input, Message } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

class DepositEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { deposit } = this.props.match.params

        if (!id) {
            id = deposit
        }

        this.state = {
            depositId: id,
            name: '',
            depositType: 0,
            depositHourlyRate: 0.0,
            depositImage: '',
            userAccessList: [],
        }
    }

    componentDidMount() {
        let { depositId } = this.state

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])
        this.state.network.getUserList()

        if (depositId) {
            this.state.network.getDeposit(depositId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        console.log('MachineEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getDeposit } = this.props
        if (getDeposit && getDeposit.fetching !== prevProps.getDeposit.fetching && !getDeposit.fetching) {
            if (getDeposit.status.success) {
                let deposit = getDeposit.data.data
                this.setState(deposit)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { depositId, name, userAccessList } = this.state

        if (name === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createDeposit({
            id: depositId,
            name,
            userAccessList
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let {
            // fetching,
            creating,
            options,
            name,
            userAccessList,
            // depositType,
            // depositHourlyRate,
            // depositImage,
            getDeposit,
        } = this.state

        console.log('DepositEditor state:', this.state)
        console.log('DepositEditor state', options)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getDeposit.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-deposit-name"
                            control={Input}
                            label="Nome Deposito"
                            name="name"
                            value={name}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-deposit-type"
                            control={Dropdown}
                            multiple
                            search
                            selection
                            options={options}
                            label="Utenti con accesso alla vetrina"
                            placeholder="Utenti"
                            name="userAccessList"
                            value={userAccessList}
                            onChange={(e, data) => {
                                let ids = data.value
                                console.log('User access selected', data)
                                this.setState({ userAccessList: ids })
                            }}
                        />
                    </Form.Group>
                    {/* <Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-image"
							control={Input}
							label="Immagine"
							placeholder="Immagine"
							name="depositImage"
							value={depositImage}
							disabled
							onChange={(e) => this.handleInput(e)}
						/>
						<PickImage
							onChange={(image) => {
								this.setState({ depositImage: image })
							}}
							value={this.state.depositImage}
						/>
					</Form.Group> */}
                    {/* <Form.Group widths="equal">
						<Form.Field
							id="form-input-control-deposit-cost"
							control={Input}
							type="number"
							label="Prezzo orario"
							placeholder="0.0"
							step="0.1"
							name="depositHourlyRate"
							value={depositHourlyRate}
							onChange={(e) => this.handleInput(e)}
						/>
					</Form.Group> */}
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu&#39; tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        // getDepositTypeList: { data: { data: types }, fetching },
        getUserList: {
            data: { data: users },
            fetching,
        },
        createDeposit: { data: createResult, fetching: creating, status: createStatus },
        getDeposit,
    } = state

    let options = []
    for (let i in users) {
        let user = users[i]
        console.log('User is', user)
        options.push({
            key: user.id_user,
            text: user.fullname,
            value: user.id_user,
        })
    }

    return {
        options,
        users,
        fetching,
        createResult,
        creating,
        getDeposit,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DepositEditor)))
