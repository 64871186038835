import {} from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Card, Icon, Input, Table } from 'semantic-ui-react'
import { floatTimeToString, timeString } from '../lib/util'
import { Confirmation, ImageDefault } from '../components'

const { app, articles, articleEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class Article extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, article } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...article,
            articleMinutes: article ? parseFloat(article.articleMinutes) : 0,
        }

        if (onCostChange && article) {
            onCostChange(this.totalCost(), article.articleId)
        }
    }

    totalCost() {
        let { articleHourlyRate, articleHours, articleMinutes } = this.state
        let total = (articleHours + articleMinutes / 60) * parseFloat(articleHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { article, articleId, network } = this.props

        if ((!article || !article.articleId) && articleId) {
            network.getArticle(articleId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getArticle, login } = this.props
        let { articleId, articleHourlyRate } = this.state
        if (articleHourlyRate !== prevState.articleHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), articleId)
            }
        }

        console.log('Article getArticle fetching', getArticle.fetching)
        if (getArticle && getArticle.fetching !== prevProps.getArticle.fetching && !getArticle.fetching) {
            console.log('Done calling getArticle')
            if (getArticle.status.success) {
                console.log(getArticle.data)
                let article = getArticle.data.data

                this.setState({ ...article })
                console.log('Calling Get article', login, article)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                console.log('calling No article found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { articleId, articleHourlyRate, articleHours, articleMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{articleId}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={articleHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    articleHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{articleHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(articleHours, articleMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideArticle, hideArticleHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideArticle && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideArticleHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideArticle, hideArticleHours, isBalance } = this.props
        let { articleId, articleHours, articleMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideArticle && <Table.Cell>{articleId}</Table.Cell>}
                    {!hideArticleHours && <Table.Cell>{timeString(articleHours, articleMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, article } = this.props

        network.deleteStdArticle(article)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, article, onClick, selected, noActions, traversable } = this.props
        let { deleting, articleId: id, articleCode, articleName, articleDescription, articleImages } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(article)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        if (article && article.articleId === 1) {
            return null
        }

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()

            default:
                return (
                    <Card className={selected ? 'rowSelected' : ''} style={styles.articleCard} {...extraprops} color={this.state.colors[col]}>
                        <Confirmation
                            message="Vuoi davvero eliminare questo articolo?"
                            showModal={deleting}
                            onConfirm={() => {
                                this.remove()
                            }}
                            onCancel={() => {
                                this.setState({ deleting: false })
                            }}
                        />
                        <Card.Content style={styles.collectionContent}>
                            <View fullw row>
                                {articleImages && articleImages.length > 0 && (
                                    <div style={styles.imageContainer}>
                                        <ImageDefault fromRoot src={articleImages[0].picturePath} ui={false} style={styles.articleImage} />
                                    </div>
                                )}
                                {!articleImages ||
                                    (articleImages.length === 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={null} ui={false} style={styles.articleImage} />
                                        </div>
                                    ))}
                                <View fullw column>
                                    <span style={styles.header}>{articleName}</span>
                                    <Card.Description>{articleDescription}</Card.Description>
                                    <Card.Meta>{articleCode}</Card.Meta>
                                </View>
                            </View>
                        </Card.Content>
                        {!noActions && (
                            <Card.Content extra>
                                {user && !!user.manage_article && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, articles, articleEdit, `${id}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Modifica
                                    </Button>
                                )}

                                {user && !!user.manage_article && (
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.openRemoveDialog()
                                        }}
                                    >
                                        <Icon name="delete" />
                                        Elimina
                                    </Button>
                                )}
                            </Card.Content>
                        )}
                        {traversable && (
                            <Card.Content extra>
                                <Button
                                    color="yellow"
                                    onClick={() => {
                                        this.props.onTraverse(article)
                                    }}
                                >
                                    <Icon name="folder" />
                                    Apri
                                </Button>

                                {user && !!user.manage_article && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, articles, articleEdit, `${id}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Modifica
                                    </Button>
                                )}
                            </Card.Content>
                        )}
                    </Card>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    articleImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    articleContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    articleCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getArticle } = state
    let rarticleId = null
    if (login && login.data && login.data.user) rarticleId = login.data.user.rarticleId

    return { role: rarticleId, getArticle, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Article)))
