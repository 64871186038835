import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { Card, Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Article, Empty } from '../components'

const { app, articles, newEl, articleEdit } = routes


class Articles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteStdArticle } = this.state
        let { organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteStdArticle.fetching !== prevState.deleteStdArticle.fetching && !deleteStdArticle.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getStdArticleList(this.state.search.data)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getStdArticleList(this.state.search.data)
            this.setState({ page: 0 })
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_article) {
            extraTools.push({
                name: 'Crea nuovo articolo',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, articles, newEl, articleEdit))
                },
            })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'right',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: 'none',
                    action: () => {
                        this.next()
                    },
                },
            ])
        }

        this.state.network.getStdArticleList(this.state.search.data)
    }

    renderArticle(key, article, style) {
        if (!article) return null

        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (article) => {
                if (onChange) {
                    onChange(article)
                }
                this.setState({ selected: article })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return (
            <div style={style}>
                <Article {...extraProps} selected={article.articleId === selected.articleId} type="full" key={`article_${key}`} article={article} />
            </div>
        )
    }

    render() {
        let { articles, fetching, page, items } = this.state
        let { noPagination, onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (articles && articles.data && articles.data.length > 0) {
            content = []
            const keyArray = Object.keys(articles.data)
            if (!noPagination) {
                for (let i = page * items; i < page * items + items; i++) {
                    content.push(this.renderArticle(i, articles.data[keyArray[i]]))
                }
            } else {
                content = (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List height={height} itemCount={articles.data.length} itemSize={200} width={width}>
                                {({ index, style }) => this.renderArticle(index, articles.data[index], style)}
                            </List>
                        )}
                    </AutoSizer>
                )
            }
        } else {
            content = <Empty />
        }

        return (
            <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { getStdArticleList, deleteStdArticle, search, organization, login } = state

    let articleList = getStdArticleList
    return {
        articles: articleList.data,
        fetching: articleList.fetching,
        deleteStdArticle,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Articles)))
